<template>
    <div class="light-buying__status accepted">
        <template v-if="requisites">
            <div class="light-buying__requisites">
                <p class="requisites_title">{{ $t('light-payment.status.title') }}</p>
                <LightBuyingCard
                    :buying="buying!"
                    :buyingIsAvailable="buyingIsAvailable"
                >
                    <template #card-timer>
                        <LightBuyingStatusTimer
                            :timeText="timeText"
                            :percent="percent"
                            :size="42"
                            :fontSize="12"
                            :borderWidth="3.5"
                            :borderColor="timerBorderColor"
                            :textColor="timerTextColor"
                            :hiddenTimer="true"
                            class="accepted"
                        />
                    </template>
                </LightBuyingCard>
            </div>
            <transition name="fade" mode="out-in">
                <template v-if="buyingIsAvailable">
                    <LightBuyingAdditional
                        :comment="requisites.getComment()"
                        :addressType="requisites.getAddressType()"
                    />
                </template>
            </transition>
        </template>
    </div>
</template>

<script setup lang="ts">
import {computed, defineAsyncComponent, PropType, ref, toRef, UnwrapRef, watch} from "vue";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import {ReactiveBuyingOperation} from "@/services/operations/AbstractBuyingService";
import LightBuyingCard from "@/targets/integration/views/light-buying/components/LightBuyingCard.vue";
import LightBuyingAdditional from "@/targets/integration/views/light-buying/components/LightBuyingRequisiteAdditional.vue";
import se from "../../../../../../www/assets/MessageNotFoundPayment-9e8abdb9";

const LightBuyingStatusTimer = defineAsyncComponent(() => import("../components/LightBuyingStatusTimer.vue"));

enum TIMER_STATUS_TYPE {
    DEFAULT = "DEFAULT",
    WARNING = "WARNING",
    ALERT = "ALERT"
}

const setTimerColors = {
    DEFAULT: "#1890FF",
    WARNING: "#E69700",
    ALERT: "#FF3B30"
};

const props = defineProps({
    buying: {
        type: Object as PropType<UnwrapRef<PurchaseOperation>>,
        required: true
    },
    timeText: {
        type: Object as PropType<UnwrapRef<string | null>>,
        required: true
    },
    percent: {
        type: Object as PropType<UnwrapRef<number>>,
        required: true
    }
});

const buying: ReactiveBuyingOperation = toRef(props, 'buying');
const requisites = computed(() => buying.value!.requisites);
const percent = toRef(props, 'percent');
const timeText = toRef(props, 'timeText');
const buyingIsAvailable = ref(true);
const timerBorderColor = ref(setTimerColors[TIMER_STATUS_TYPE.DEFAULT]);
const timerTextColor = ref(setTimerColors[TIMER_STATUS_TYPE.DEFAULT]);

detectBuyingIsAvailable(timeText.value);

watch(timeText, (value) => {
    if (value) {
        detectBuyingIsAvailable(value);
    }
});

function detectBuyingIsAvailable(value: string | null) {
    if (value === null) {
        buyingIsAvailable.value = false;
        return;
    }

    const minute = +value.split(':')[0];
    const seconds = +value.split(':')[1];

    if (minute <= 4 && seconds < 59) {
        timerBorderColor.value = setTimerColors[TIMER_STATUS_TYPE.WARNING];
        timerTextColor.value = setTimerColors[TIMER_STATUS_TYPE.WARNING];
    } else if (minute == 0 && seconds <= 59) {
        timerBorderColor.value = setTimerColors[TIMER_STATUS_TYPE.ALERT];
        timerTextColor.value = setTimerColors[TIMER_STATUS_TYPE.ALERT];
    } else if (minute < 0) {
        buyingIsAvailable.value = false;
    }
}

</script>

<style src="./index.scss"></style>
<style lang="scss">
.light-buying__status {
    &.accepted {
        padding-top: 24px;

        .light-buying__requisites {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .requisites {
                &_title {
                    @include text(16px, 700, var(--brown1), 19.2px);
                }
            }
        }
    }
}
</style>