<template>
    <f7-navbar class="light-buying-navbar">
        <f7-nav-left>
            <a href="#" class="link icon-only" @click.prevent="back">
                <i class="icon-back icon"></i>
            </a>
        </f7-nav-left>
        <f7-nav-title>
            <template v-if="buying && buying.id">
                <div class="buying-meta">
                    <p class="buying-meta__id">{{ $t('g.request') }} {{ buying.id }}</p>
                    <LightBuyingNavbarBuyingAmount :amount-u-s-d="buying.amountUsd" :amount="buying.amount"/>
                </div>
            </template>
        </f7-nav-title>
        <f7-nav-right>
            <a v-if="showBuyingInfo" href="#" class="link icon-only"
               @click="openInfoPopup"
            >
                <i class="icon info-svg"></i>
            </a>
        </f7-nav-right>
        <transition
            mode="out-in"
            enter-active-class="slide-in__top-block"
            leave-active-class="slide-out__top-block"
        >
            <template v-if="buying && buying.account && showSubnavbar">
                <div class="light-buying-subnavbar">
                    <div class="subnavbar-agent">
                        <p class="subnavbar-agent_name">{{ buying.account }}</p>
                        <p class="subnavbar-agent_online">
                            <template v-if="buying.agent?.isOnline">
                                <i class="icon green"></i>
                                <p>Online</p>
                            </template>
                            <template v-else>
                                <i class="icon red"></i>
                                <p>Offline</p>
                            </template>
                        </p>
                    </div>
                    <p class="subnavbar-chat" @click="toChat"
                       v-if="isShowChatButton">
                        <i class="icon chat-svg"></i>
                        {{ $t('g.chat-with-agent') }}
                        <i class="icon subnavbar-chat__mark" v-if="buying.chat && buying.chat.unread > 0"></i>
                    </p>
                </div>
            </template>
        </transition>
    </f7-navbar>
</template>

<script setup lang="ts">
import {computed, onMounted, PropType, ref, Ref, toRef, UnwrapRef, watch} from "vue";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import Account from "@/entities/Account";
// @ts-ignore
import store from "@target/core/store/store";
import {f7} from "framework7-vue";
import Helper from "@/helpers/helper";
import {ReactiveBuyingOperation} from "@/services/operations/AbstractBuyingService";
import LightBuyingNavbarBuyingStatus
    from "@/targets/integration/views/light-buying/components/LightBuyingNavbarBuyingStatus.vue";
import LightBuyingNavbarBuyingAmount
    from "@/targets/integration/views/light-buying/components/LightBuyingNavbarBuyingAmount.vue";
import {BuyingOperationStatus} from "@enums/BuyingOperationStatus";
import IFrameService from "@/services/iframe/IFrameService";
import UtilsService from "@/services/others/UtilsService";

const props = defineProps({
    buying: {
        type: Object as PropType<UnwrapRef<PurchaseOperation> | null>,
        required: true
    }
});

const showSubnavbar = ref(false);
const account = computed((): Account => store.getters.account.value);
const buying: ReactiveBuyingOperation = toRef(props, 'buying');
const emits = defineEmits(['clickBack', 'openInfoPopup']);
const showBuyingInfo = computed(() => {
    return !!(buying.value &&
        buying.value.status && [
            BuyingOperationStatus.CASHED,
            BuyingOperationStatus.CANCELED,
        ].includes(buying.value.status));
})

const isShowChatButton = computed(() => {
    return buying.value && !account.value.settings.isBanned;
});

function toChat() {
    if (buying.value.chat === null) {
        UtilsService.of().openTelegramSupportChat();
        return;
    }

    // @ts-ignore
    f7.views.higher.router.navigate('/chat', {
        openIn: 'popup'
    });
}

function openInfoPopup() {
    emits('openInfoPopup');
}

onMounted(() => {
    showSubnavbar.value = true;
})

function back() {
    emits('clickBack');
    showSubnavbar.value = false;
}
</script>

<style lang="scss">
.light-buying-navbar {
    --f7-subnavbar-bg-color: #fff;
    --f7-subnavbar-height: 44px;

    .navbar-bg {
        &::after {
            content: none;
        }
    }

    .light-buying-subnavbar {
        position: absolute;
        left: 0;
        right: 0;
        top: 44px;
        height: 44px;
        z-index: 9999;
        width: calc(100% - 32px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px;

        &::after {
            content: '';
            position: absolute;
            background-color: var(--f7-navbar-border-color, var(--f7-bars-border-color));
            display: block;
            z-index: 15;
            top: auto;
            right: auto;
            bottom: 0;
            left: 0;
            height: 1px;
            width: 100%;
            transform-origin: 50% 100%;
            transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
        }

        .subnavbar-agent {
            display: flex;
            flex-direction: column;
            gap: 2px;

            &_name {
                @include text(16px, 500, var(--brown1), 19.2px);
            }

            &_online {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 4px;
                @include text(12px, 400, #818E95, 14.4px);

                i {
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;

                    &.green {
                        background: #32BF61;
                    }

                    &.red {
                        background: #818E95;
                    }
                }
            }
        }

        .subnavbar-chat {
            display: flex;
            align-items: center;
            gap: 4px;
            padding: 0 12px;
            border-radius: 100px;
            background-color: var(--grey9);
            @include text(12px, 500, var(--grey2), 27px);
            height: 27px;
            position: relative;

            &.active-state {
                background-color: #00000026;
            }

            &__mark {
                position: absolute;
                top: 0;
                right: 0;
                width: 8px;
                height: 8px;
                background-color: #FF3B30;
                border-radius: 50%;
            }

            .chat-svg {
                width: 14px;
                height: 14px;
                filter: brightness(0) saturate(100%) invert(58%) sepia(22%) saturate(169%) hue-rotate(157deg) brightness(91%) contrast(91%);
            }
        }
    }

    .buying-meta {
        display: flex;
        flex-direction: column;

        &__id {
            @include text(16px, 500, var(--brown1), 19.2px);
        }
    }
}

</style>