<template>
    <f7-popup @popup:open="popupOpen" ref="self" class="light-buying-info-popup" swipe-to-close="to-bottom" v-model:opened="isOpened">
        <span class="tint"></span>
        <f7-page no-navbar :page-content="false">
            <f7-page-content>
                <div class="wrapper-elements" v-if="buying">
                    <LightBuyingStatusAccepted
                        :buying="buying"
                        :timeText="null"
                        :percent="null"
                    />
                    <LightBuyingMeta :buying="buying"/>
                </div>
            </f7-page-content>
        </f7-page>
    </f7-popup>
</template>

<script setup lang="ts">
import {defineAsyncComponent, ref} from "vue";
import {ReactiveBuyingOperation} from "@/services/operations/AbstractBuyingService";
import LightBuyingMeta from "@/targets/integration/views/light-buying/components/LightBuyingMeta.vue";

const LightBuyingStatusAccepted = defineAsyncComponent(() => import("../contents/LightBuyingStatusAccepted.vue"));

const isOpened = ref(false);
const buying: ReactiveBuyingOperation = ref(null);
const self = ref(null);
defineExpose({isOpened, buying,detectHeight});

function popupOpen() {
    setTimeout(() => {
        detectHeight();
    }, 100);
}

function detectHeight() {
    const mainEl = (self.value! as any);
    const style = getComputedStyle(document.body);
    const contentHeight = mainEl.$el.querySelector('.wrapper-elements').offsetHeight;
    mainEl.$el.style.height = contentHeight + parseInt(style.getPropertyValue('--f7-safe-area-bottom')) + 17 + 16 + 'px';
}
</script>

<style lang="scss">
.light-buying-info-popup {
    --f7-page-content-extra-padding-top: 16px;
    --f7-page-bg-color: #FFF;
    bottom: 0 !important;
    top: unset !important;
    border-top-left-radius: 16px !important;
    border-top-right-radius: 16px !important;
    padding-bottom: calc(0px + var(--f7-safe-area-bottom)) !important;

    overflow: hidden;
    .tint {
        width: 36px;
        height: 4px;
        position: absolute;
        top: 6px;
        z-index: 9999;
        background: var(--grey4);
        border-radius: 4px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%)
    }
}
</style>