<template>
    <f7-popup ref="new-payment-popup" class="new-payment-popup" @popup:open="open"
              @popup:opened="opened" @popup:close="eventClosePopup">
        <span class="tint"></span>
        <f7-view url="/popup/new-payment">
        </f7-view>
    </f7-popup>
</template>

<script lang="ts">
// @ts-ignore
import store from "@target/core/store/store";
import NewPaymentController from "@/views/new-payment/NewPaymentController";
import FindAgentController from "@/views/new-payment/FindAgentController";

export default {
    components: {},
    setup() {
        const vcNP = NewPaymentController.getInstance();
        const vcFA = FindAgentController.getInstance();
        return {
            store,
            vcNP,
            vcFA
        };
    },
    methods: {
        open() {
        },
        opened() {
            const popupHTML = document.querySelector('.new-payment-popup');
            (popupHTML as HTMLDivElement).classList.add('transition');
        },
        eventClosePopup(e: any) {
            this.close();
        },
        async close() {
            const popupHTML = document.querySelector('.new-payment-popup');
            (popupHTML as HTMLDivElement).classList.remove('transition');

            setTimeout(() => {
                this.vcNP.destructor();
                this.vcFA.destructor();
            }, 500);
        }
    }
}
</script>

<style lang="scss">
.new-payment-popup {
    //height: calc(var(--f7-safe-area-bottom) + 406px) !important;
    bottom: 0px !important;
    top: unset !important;
    border-top-left-radius: 16px !important;
    border-top-right-radius: 16px !important;

    .tint {
        width: 36px;
        height: 4px;
        position: absolute;
        top: 6px;
        z-index: 9999;
        background: #818E95;
        border-radius: 4px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%)
    }

    &.transition {
        transition: 0.4s ease height;
    }
}
.with-modal-popup {
    #app {
        .new-payment-popup {
            &.height-100 {
                height: calc(100% - var(--f7-safe-area-top)) !important;
            }
        }
    }
}

//.sheet-modal-new-payment {
//    border-top-left-radius: 16px;
//    border-top-right-radius: 16px;
//
//    .sheet-modal-inner {
//        border-top-left-radius: inherit;
//        border-top-right-radius: inherit;
//    }
//
//    .title {
//        font-weight: 700;
//        font-size: 22px;
//        line-height: 120%;
//        letter-spacing: 0.337647px;
//        color: #000000;
//    }
//}
</style>
