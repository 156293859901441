<template>
    <f7-page
        class="light-payment__page"
        :page-content="false"
    >
        <LightBuyingNavbar
            class="light-payment__navbar"
            :buying="buying"
            @clickBack="toPayments"
            @openInfoPopup="openInfoPopup"
            ref="lightPaymentNavbar"
        />
        <f7-page-content ptr @ptr:refresh="refresh">
            <LightBuyingContent
                v-if="buying && !pageLoading"
                :buying="buying"
                :timerService="timerService"
                @timerExpired="updateData"
                @cancelPurchase="cancelPurchase"
            />
            <LightPaymentLoader v-else/>
        </f7-page-content>

        <transition
            mode="out-in"
            enter-active-class="slide-in__bottom-block"
            leave-active-class="slide-out__bottom-block"
        >
            <template v-if="buying && buying.status === BuyingOperationStatus.ACCEPTED && percent > 0">
                <div class="bottom-block">
                    <f7-button
                        class="theme-type"
                        :loading="submitCancelBtnLoading"
                        :disabled="submitBtnsLoading"
                        preloader
                        @click="cancelPurchase"
                    >
                        {{ $t('light-payment.submit.cancel') }}
                    </f7-button>
                    <f7-button
                        class="theme-type"
                        :loading="submitPaidBtnLoading"
                        :disabled="submitBtnsLoading"
                        preloader
                        @click="submitPaidBtnClick"
                    >
                        {{ $t('light-payment.submit.paid') }}
                    </f7-button>
                </div>
            </template>
        </transition>
        <light-buying-info-popup ref="lightBuyingInfoPopup" />
    </f7-page>
</template>

<script setup lang="ts">
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import {f7} from "framework7-vue";
// @ts-ignore
import store from "@target/core/store/store";
import {computed, defineAsyncComponent, onBeforeMount, onMounted, onUnmounted, Ref, ref} from "vue";
import LightBuyingService from "@/services/light-buying/LightBuyingService";
import LightPaymentLoader from "./LightBuyingLoader.vue";

const LightBuyingContent = defineAsyncComponent(() => import("@/targets/integration/views/light-buying/LightBuyingContent.vue"));
import {BuyingOperationStatus} from "@enums/BuyingOperationStatus";
import {ReactivePercent} from "@/services/light-buying/LightBuyingTimerService";
import i18n from "@/langs/i18n";
import {useI18n} from "vue-i18n";
import LightBuyingNavbar from "@/targets/integration/views/light-buying/components/LightBuyingNavbar.vue";
import LightBuyingInfoPopup from "@/targets/integration/views/light-buying/components/LightBuyingInfoPopup.vue";

const appController = AppController.getInstance();
const lightBuyingService = appController.lightBuyingService as LightBuyingService;
const buying = lightBuyingService.buying;
const timerService = lightBuyingService.timerService;
const pageLoading = ref(false);
const submitPaidBtnLoading = ref(false);
const submitCancelBtnLoading = ref(false);
const submitBtnsLoading = ref(false);
const percent: ReactivePercent = timerService.getPercent;
const {t} = useI18n({useScope: 'global'});
const lightPaymentNavbar = ref(null);
const lightBuyingInfoPopup: Ref<any> = ref(null);

onBeforeMount(async () => {
    if (lightBuyingService.forceLoadingData) {
        await fetchData();
        lightBuyingService.forceLoadingData = false;
    }

})

async function fetchData() {
    try {
        pageLoading.value = true;
        await lightBuyingService.fetch();
    } catch (errors) {
        console.error(errors);
    } finally {
        pageLoading.value = false;
    }
}

function openInfoPopup() {
    lightBuyingInfoPopup.value!.buying = buying.value;

    setTimeout(() => {
        lightBuyingInfoPopup.value.detectHeight()
        lightBuyingInfoPopup.value!.isOpened = true;
    }, 0);
}

async function cancelPurchase() {
    const title = buying.value?.status === BuyingOperationStatus.ACCEPTED ?
        i18n.global.t("light-payment.status.accepted.alerts.cancel.title") :
        i18n.global.t("light-payment.status.created.alerts.cancel.title");
    const text = buying.value?.status === BuyingOperationStatus.ACCEPTED ?
        i18n.global.t("light-payment.status.accepted.alerts.cancel.text") :
        i18n.global.t("light-payment.status.created.alerts.cancel.text");
    f7.dialog.create({
        title: title,
        text: text,
        buttons: [
            {
                text: i18n.global.t("findAgent.cancel.no"),
            },
            {
                text: i18n.global.t("findAgent.cancel.yes"),
                onClick: async () => {
                    try {
                        submitCancelBtnLoading.value = true;
                        submitBtnsLoading.value = true;
                        f7.preloader.show();
                        await lightBuyingService.cancel();
                        toPayments();
                    } catch (e: any) {
                        f7.dialog.alert(t('g.errors.alert.default-text'), t('g.errors.alert.default-title'));
                    } finally {
                        submitCancelBtnLoading.value = false;
                        submitBtnsLoading.value = false;
                        f7.preloader.hide();
                    }
                }
            }
        ]
    }).open();
}

function toPayments() {
    f7.view.main.router.back('/payments', {force: true, clearPreviousHistory: true});
}

async function updateData() {
    await lightBuyingService.fetch();
    timerService.stopInterval();
}

async function submitPaidBtnClick() {
    try {
        submitPaidBtnLoading.value = true;
        submitBtnsLoading.value = true;
        await lightBuyingService.confirm();
    } catch (e: any) {
        f7.dialog.alert(t('g.errors.alert.default-text'), t('g.errors.alert.default-title'));
    } finally {
        submitPaidBtnLoading.value = false;
        submitBtnsLoading.value = false;
    }
}

async function refresh(done: Function) {
    await lightBuyingService.fetch();
    setTimeout(async () => {
        done();
    }, 1000);
}

onUnmounted(() => {
    lightBuyingService.reset();
});
</script>

<style lang="scss">
.light-payment__navbar {
    --f7-navbar-bg-color: #FFF;
    --f7-navbar-border-color: var(--grey8);

    .right {
        min-width: 44px;

        .chat-svg {
            width: 24px;
            height: 24px;
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.92072 21C7.11848 21 9.53985 19.8698 11.3667 18.6056C17.4502 18.7144 22 15.2986 22 10.8112C22 6.48279 17.545 3 11.9957 3C6.44636 3 2 6.48279 2 10.8112C2 13.5656 3.75786 16.0186 6.45498 17.2995C6.08445 17.9777 5.42956 18.907 5.05903 19.3926C4.50754 20.1042 4.88669 21 5.92072 21ZM6.96338 19.267C6.85136 19.3088 6.80827 19.2502 6.88583 19.1581C7.32529 18.6391 7.97156 17.8102 8.29039 17.2242C8.5489 16.7637 8.46273 16.3619 7.91124 16.1107C5.28307 14.9135 3.75786 12.9963 3.75786 10.8112C3.75786 7.44558 7.41146 4.70791 11.9957 4.70791C16.5799 4.70791 20.2335 7.44558 20.2335 10.8112C20.2335 14.1767 16.5799 16.9228 11.9957 16.9228C11.8406 16.9228 11.5993 16.9144 11.3063 16.9144C10.9358 16.906 10.6601 17.0149 10.3154 17.2744C9.27273 18.0279 7.73029 18.9656 6.96338 19.267Z' fill='%23007AFF'/%3E%3Cline x1='8.4' y1='9.1' x2='15.6' y2='9.1' stroke='%23007AFF' stroke-width='1.8' stroke-linecap='round'/%3E%3Cline x1='9.9' y1='12.6' x2='14.1' y2='12.6' stroke='%23007AFF' stroke-width='1.8' stroke-linecap='round'/%3E%3C/svg%3E%0A");
        }
    }
}

.light-payment__page {
    --f7-page-content-extra-padding-top: 44px;
    --f7-page-bg-color: #FFF;

    .page-content {
        &::-webkit-scrollbar {
            width: 0;
        }
    }

    //.bottom-block {
    //    background-color: #FFFFFF;
    //    position: absolute;
    //    left: 0;
    //    right: 0;
    //    bottom: var(--f7-safe-area-bottom);
    //    padding: 16px;
    //    z-index: 100;
    //    display: flex;
    //    gap: 16px;
    //
    //    a {
    //        flex: 2;
    //
    //        &:nth-child(1) {
    //            flex: 1 0 46px;
    //            background: #8E8E93;
    //        }
    //    }
    //
    //    &::before {
    //        content: '';
    //        position: absolute;
    //        background-color: var(--grey8);
    //        display: block;
    //        z-index: 15;
    //        top: 0;
    //        right: 0;
    //        bottom: 0;
    //        left: 0;
    //        height: 1px;
    //        width: 100%;
    //        transform-origin: 50% 100%;
    //        transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
    //    }
    //}
}
</style>