<template>
    <f7-page ref="self" :page-content="false" class="change-lang-page">
        <f7-navbar>
            <f7-nav-left>
                <a href="#" class="link icon-only" @click="back">
                    <i class="icon-back icon"></i>
                </a>
            </f7-nav-left>
            <f7-nav-title>
                {{ $t("g.settings.selectLanguage.title") }}
            </f7-nav-title>
            <f7-nav-right></f7-nav-right>
        </f7-navbar>
        <f7-page-content>
            <f7-list class="languages list-dividers list-strong list-outline">
                <f7-list-item @change="update" radio radio-icon="end" :title="$t('g.settings.selectLanguage.languages.ru')" value="ru"
                    name="currency" :checked="account.settings.localization == 'ru'"></f7-list-item>
                <f7-list-item @change="update" radio radio-icon="end" :title="$t('g.settings.selectLanguage.languages.en')"
                    value="en" name="currency" :checked="account.settings.localization == 'en'"></f7-list-item>
            </f7-list>
        </f7-page-content>
    </f7-page>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n'
import { f7 } from 'framework7-vue';
import { computed, defineComponent, Ref, ref } from "@vue/runtime-core";
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import Account from '@/entities/Account';
import store from "@target/core/store/store";
import ApiService from '@/targets/integration/services/ApiService';
import LangService from '@/services/LangService';
import Message, {MessageType} from "@/entities/Message";
import i18n from "@/langs/i18n";
import {getDateString} from "@/views/payment/ts/helper";

export default defineComponent({
    setup() {
        const { t } = useI18n({ useScope: 'global' });
        const account = computed((): Account => store.getters.account.value);
        const shortName = ref(account.value.shortName && account.value.shortName.length > 0 ? account.value.shortName.slice() : '');
        const disable = ref(false);
        const loading = ref(false);
        // const client = store.getters.client.value;

        return {
            // client,
            t,
            disable,
            loading,
            shortName,
            account
        }
    },
    watch: {
        'shortName'(val: string) {
            if (val.length === 0) {
                this.disable = true;
            } else {
                this.disable = false;
            }
        }
    },
    methods: {
        async update(e: any) {
            try {
                f7.preloader.show();
                // console.log(e.target.value);
                await store.dispatch('patchAccountData', { localization: e.target.value });
                LangService.getInstance().set(e.target.value);
                this.checkBanState();
                // await AppController.getInstance().auth(localStorage.getItem('token'));
                // await store.dispatch('fetchAccount', null);
                // await store.dispatch('fetchPaymentSystems', null);
                // await store.dispatch('fetchCurrencies', null);
                // await store.dispatch('fetchMyPayments', { refresh: true });
                f7.view.main.router.back();
            } catch (e: any) {
                f7.dialog.alert(this.t("g.settings.changeName.alert.first"), this.t("g.settings.changeName.alert.second"));
            } finally {
                f7.preloader.hide();
            }
        },
        back() {
            f7.view.main.router.back();
        },
        async save() {
            try {
                this.loading = true;
                this.disable = true;
                await store.dispatch('patchAccountData', { name: this.shortName });
                f7.view.main.router.back();
            } catch (e: any) {
                f7.dialog.alert(this.t("g.settings.changeName.alert.first"), this.t("g.settings.changeName.alert.second"));
            } finally {
                this.loading = false;
                this.disable = false;
            }
        },
        checkBanState() {
            const account: Account = store.getters['account'].value;

            if (account.settings.isBanned) {
                setTimeout(async () => {
                    const message: Message = {
                        // @ts-ignore
                        title: i18n.global.t("g.ban.title"),
                        // text: account.ban.comment,
                        text: i18n.global.t("g.ban.text"),
                        extra: i18n.global.t('g.ban.extra', { date: getDateString(new Date(account.settings.ban.unbanAt)) }),
                        type: MessageType.error
                    }
                    await store.dispatch('setMessage', message);
                }, 500);
                return;
            }
        }
    },
    unmounted() {

    },
});
</script>

<style lang="scss">
.change-lang-page {
    .title-wrapper {
        align-items: center;
        margin: 27px 16px 5px;
        display: flex;
        justify-content: flex-start;
        gap: 8px;
        cursor: pointer;

        .title {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            text-transform: uppercase;
            color: #818E95;
        }
    }
    .form {
        .input-wrapper  {
            position: relative;
            &::after {
                content: '';
                position: absolute;
                background-color: var(--grey4);
                display: block;
                z-index: 15;
                top: auto;
                right: 0px;
                bottom: 0;
                left: auto;
                height: 1px;
                width: 100%;
                transform-origin: 50% 100%;
                transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
            }
            &::before {
                content: '';
                position: absolute;
                background-color: var(--grey4);
                display: block;
                z-index: 15;
                top: 0;
                right: 0px;
                bottom: auto;
                left: auto;
                height: 1px;
                width: 100%;
                transform-origin: 50% 100%;
                transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
            }
            .short-name {
                width: 100%;
                padding: 12px 16px;
                background-color: #FFF;
                height: 44px;
                @include text(16px, 400, var(--brown1));
                position: relative;

                &::placeholder {
                    color: #BFBFBF;
                }
            }
        }
        .hint {
            padding: 8px 16px 0px;
            @include text(14px, 400, #818E95, 16.8px);
        }

        & + .block {
            margin-top: 40px;
        }
    }
}
</style>
