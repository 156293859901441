<template>
    <f7-page ref="self" :page-content="false" class="change-short-name-page">
        <f7-navbar>
            <f7-nav-left>
                <a href="#" class="link icon-only" @click="back">
                    <i class="icon-back icon"></i>
                </a>
            </f7-nav-left>
            <f7-nav-title>
                {{ $t("g.settings.changeName.title") }}
            </f7-nav-title>
            <f7-nav-right></f7-nav-right>
        </f7-navbar>
        <f7-page-content>
            <div class="title-wrapper">
                <span class="title">{{ $t("g.settings.changeName.title") }}</span>
            </div>
            <div class="form">
                <div class="input-wrapper">
                    <input class="short-name" type="text" placeholder="Введите ваше имя" v-model="shortName" />
                </div>
                <p class="hint" v-html="$t('g.settings.changeName.hint')" />
            </div>
            <f7-block>
                <f7-button class="flex-1" fill :disabled="disable" preloader :loading="loading" @click="save">{{ $t("g.settings.changeName.submit") }}</f7-button>
            </f7-block>
        </f7-page-content>
    </f7-page>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n'
import { f7 } from 'framework7-vue';
import { computed, defineComponent, Ref, ref } from "@vue/runtime-core";
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import Account from '@/entities/Account';
import store from "@target/core/store/store";
import ApiService from '@/targets/integration/services/ApiService';

export default defineComponent({
    setup() {
        const { t } = useI18n({ useScope: 'global' });
        const account = computed((): Account => store.getters.account.value);
        const shortName = ref(account.value.shortName && account.value.shortName.length > 0 ? account.value.shortName.slice() : '');
        const disable = ref(false);
        const loading = ref(false);

        return {
            t,
            disable,
            loading,
            shortName,
            account
        }
    },
    watch: {
        'shortName'(val: string) {
            if (val.length === 0) {
                this.disable = true;
            } else {
                this.disable = false;
            }
        }
    },
    methods: {
        async updateCurrency(e: any) {
            try {
                f7.preloader.show();
                // console.log(e.target.value);
                await store.dispatch('patchClientData', { currency: e.target.value });
                await AppController.getInstance().auth(localStorage.getItem('token'));
                await store.dispatch('fetchAccount', null);
                await store.dispatch('fetchPaymentSystems', null);
                await store.dispatch('fetchCurrencies', null);
                await store.dispatch('fetchMyPayments', { refresh: true });
                f7.view.main.router.back();
            } catch (e: any) {
                f7.dialog.alert(this.t("g.settings.changeName.alert.first"), this.t("g.settings.changeName.alert.second"));
            } finally {
                f7.preloader.hide();
            }
        },
        back() {
            f7.view.main.router.back();
        },
        async save() {
            try {
                this.loading = true;
                this.disable = true;
                await store.dispatch('patchAccountData', { name: this.shortName });
                f7.view.main.router.back();
            } catch (e: any) {
                f7.dialog.alert(this.t("g.settings.changeName.alert.first"), this.t("g.settings.changeName.alert.second"));
            } finally {
                this.loading = false;
                this.disable = false;
            }
        }
    },
    unmounted() {

    },
});
</script>

<style lang="scss">
.change-short-name-page {
    .title-wrapper {
        align-items: center;
        margin: 27px 16px 5px;
        display: flex;
        justify-content: flex-start;
        gap: 8px;
        cursor: pointer;

        .title {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            text-transform: uppercase;
            color: #818E95;
        }
    }
    .form {
        .input-wrapper  {
            position: relative;
            &::after {
                content: '';
                position: absolute;
                background-color: var(--grey4);
                display: block;
                z-index: 15;
                top: auto;
                right: 0px;
                bottom: 0;
                left: auto;
                height: 1px;
                width: 100%;
                transform-origin: 50% 100%;
                transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
            }
            &::before {
                content: '';
                position: absolute;
                background-color: var(--grey4);
                display: block;
                z-index: 15;
                top: 0;
                right: 0px;
                bottom: auto;
                left: auto;
                height: 1px;
                width: 100%;
                transform-origin: 50% 100%;
                transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
            }
            .short-name {
                width: 100%;
                padding: 12px 16px;
                background-color: #FFF;
                height: 44px;
                @include text(16px, 400, var(--brown1));
                position: relative;

                &::placeholder {
                    color: #BFBFBF;
                }
            }
        }
        .hint {
            padding: 8px 16px 0px;
            @include text(14px, 400, #818E95, 16.8px);
        }

        & + .block {
            margin-top: 40px;
        }
    }
}
</style>
