import Account from "@/entities/Account";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import {Expose} from "class-transformer";

export default class LightPaymentPidResponseCredentials {
    @Expose({name: 'token'})
    private readonly _token: string;
    @Expose({name: 'refreshToken'})
    private readonly _refreshToken: string;
    @Expose({name: 'csp'})
    private readonly _csp: any;

    public constructor(token: string, refreshToken: string, csp: string) {
        this._token = token;
        this._refreshToken = refreshToken;
        this._csp = csp;
    }

    get token(): string {
        return this._token;
    }

    get refreshToken(): string {
        return this._refreshToken;
    }

    get csp(): string {
        return this._csp;
    }
}