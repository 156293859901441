<template>
<f7-page class="no-data-page">
    <SlothBlock
        :title="$t('g.errors.something-wrong')"
        :subtitle="$t('g.errors.try-reload')"
        :type="SLOTH_TYPE.SLEEP"
        @click="openTechPage()"
    >
        <template slot="button">
            <f7-button
                preloader
                class="mini grey"
                :loading="false"
                :disabled="false"
                @click="reloadPage"
            >{{ $t('g.errors.reload') }}
            </f7-button>
        </template>
    </SlothBlock>
</f7-page>
</template>

<script setup lang="ts">
import SlothBlock from "@/components/messages/SlothBlock.vue";
import {SLOTH_TYPE} from "@/entities/enums/SlothType";
import {f7} from "framework7-vue";

let techCount = 0;

function reloadPage() {
    window.location.reload();
}

function openTechPage() {
    techCount++;

    if (techCount === 6) {
        f7.dialog.prompt("", "Password", (password: string) => {
            if (password === '666') {
                f7.view.current.router.navigate('/settings/tech');
            }
        })
        techCount = 0;
    }
}
</script>
