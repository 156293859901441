<template>
    <div class="requisites_card">
        <div class="requisites_card__header">
            <div class="amount">
                <p>{{ buying!.amount }} {{ buying!.currency!.getABBR() }}</p>
            </div>
            <div class="timer-place">
                <slot name="card-timer"></slot>
            </div>
        </div>
        <div class="requisites_card__body" :class="{'unavailable': !buyingIsAvailable}">
            <transition name="fade" mode="out-in">
                <template v-if="buyingIsAvailable && requisites">
                    <div class="available-content">
                        <div class="address" @click="copy()">
                            <template v-if="requisites.getAddressType() === BuyingRequisiteAddressTypes.PHONE">
                                <p class="title">{{ $t('light-payment.requisites.with-phone') }}</p>
                            </template>
                            <template v-else-if="requisites.getAddressType() === BuyingRequisiteAddressTypes.CARD">
                                <p class="title">{{ $t('light-payment.requisites.with-card') }}</p>
                            </template>
                            <p class="text">{{ buying!.requisites!.getAddress() }}
                                <i class="display-block copy-svg"></i>
                            </p>

                        </div>
                        <div class="agent">
                            <p class="title">{{ $t('light-payment.requisites.recipient') }}</p>
                            <p class="text">{{ buying!.account }}</p>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="unavailable-content">
                        <p class="text">{{ $t('light-payment.requisites.expired-text') }}</p>
                        <p class="text">{{ $t('light-payment.requisites.expired-advice') }}</p>
                    </div>
                </template>
            </transition>
        </div>
    </div>
</template>

<script setup lang="ts">
import {computed, ComputedRef, PropType, Ref, ref, toRef, UnwrapRef} from "vue";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import {ReactiveBuyingOperation} from "@/services/operations/AbstractBuyingService";
import {f7} from "framework7-vue";
import {BuyingRequisiteAddressTypes} from "@enums/BuyingRequisiteAddressTypes";
import {useI18n} from "vue-i18n";

const props = defineProps({
    buying: {
        type: Object as PropType<UnwrapRef<PurchaseOperation>>,
        required: true
    },
    buyingIsAvailable: {
        type: Object as PropType<Boolean>,
        required: true
    }
});

const {t} = useI18n({useScope: 'global'});
const buying: ReactiveBuyingOperation = toRef(props, 'buying');
const requisites = computed(() => buying.value!.requisites);
const buyingIsAvailable = toRef(props, 'buyingIsAvailable');


function copy() {
    if (!requisites.value) return;
    const aux = document.createElement("input");
    aux.setAttribute("value", requisites.value.getAddress()!);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);

    f7.toast.create({
        text: t('g.copied'),
        position: 'top',
        closeTimeout: 1000,
    }).open();
};
</script>

<style lang="scss">
.requisites_card {
    width: 100%;
    border-radius: 18px;
    box-sizing: border-box;
    padding: 14px 14px 20px 20px;
    background-color: var(--grey9);
    border: 0.5px solid var(--grey8);
    display: flex;
    flex-direction: column;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .amount {
            @include text(24px, 700, var(--brown1), 28.8px);
        }

        .timer-place {
            height: 42px;
            max-height: 42px;
        }
    }

    &__body {
        margin-top: 12px;
        flex: 1;
        display: flex;
        flex-direction: column;

        .available-content {
            display: flex;
            flex-direction: column;
            gap: 16px;
            flex: 1;
        }

        &.unavailable {
            margin-top: 18px;
            //height: calc(100% - 12px - 42px - 14px);
        }

        .unavailable-content {
            align-items: center;
            justify-content: center;
            display: flex;
            flex-direction: column;
            gap: 8px;
            //height: calc(100% - 42px - 14px);

            .text {
                text-align: center;
                @include text(16px, 400, var(--grey2), 19.2px);
            }
        }

        .address {
            display: flex;
            flex-direction: column;
            gap: 2px;

            .title {
                @include text(14px, 400, var(--grey2), 19.2px);
            }

            .text {
                display: inline-flex;
                align-items: center;
                gap: 8px;
                @include text(20px, 500, var(--brown1), 24px);

                i {
                    margin-top: 2px;
                    cursor: pointer;
                }
            }
        }

        .agent {
            display: flex;
            flex-direction: column;
            gap: 2px;

            .title {
                @include text(14px, 400, var(--grey2), 19.2px);
            }

            .text {
                @include text(20px, 500, var(--brown1), 24px);
            }
        }
    }
}
</style>