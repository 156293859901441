<template>
    <f7-page class="message-view" name="message-view" :page-content="false">
        <f7-navbar class="message-view__navbar">
            <f7-nav-left>
                <a href="#" class="link icon-only" @click="TO_SETTINGS">
                    <i class="icon i-svg-settings"></i>
                </a>
            </f7-nav-left>
            <f7-nav-title></f7-nav-title>
            <f7-nav-right>
                <a href="#" class="link icon-only">
                    <i class="icon i-svg-wallet"></i>
                    <div class="display-flex flex-direction-column justify-content-center">
                        <p class="currency">{{ account.balance!.amountC }} {{ account.currency.abbr }}</p>
                        <p class="crypt">{{ account.balance!.amount }} $</p>
                    </div>
                </a>
            </f7-nav-right>
        </f7-navbar>
        <f7-page-content :class="{ 'bg-white': bgWhite }">
            <message-error v-if="message.type === messageType.error" :message="message"/>
            <message-success v-if="message.type === messageType.success" :message="message"/>
            <message-not-found-payment v-if="message.type === messageType.notFoundPayment" :message="message"/>
        </f7-page-content>
    </f7-page>
</template>

<script lang="ts">
import MessageViewController from "./ts/MessageViewController";
import {computed, defineAsyncComponent} from "vue";
import {MessageType} from "@/entities/Message";
import Payment from "@/entities/Payment";
import store from "@target/core/store/store";
import Account from "@/entities/Account";
import { f7 } from "framework7-vue";
import message from "framework7-vue/components/message";

export default {
    components: {
        'message-error': defineAsyncComponent(() => import('@/components/messages/error/MessageError.vue')),
        'message-success': defineAsyncComponent(() => import('@/components/messages/success/MessageSuccess.vue')),
        'message-not-found-payment': defineAsyncComponent(() => import('@/components/messages/not-found-payment/MessageNotFoundPayment.vue'))
    },
    setup() {
        const vc = new MessageViewController();
        const {message} = vc;
        const account = computed((): Account => store.getters.account.value);
        const messageType = MessageType;
        const bgWhite = computed(() => {
            if (message.type === messageType.success) return true;
            return false;
        });
        const payment: Payment = computed((): Payment => store.getters.payment.value).value;
        return {
            account,
            vc,
            payment,
            bgWhite,
            messageType,
            message
        }
    },
    methods: {
        TO_SETTINGS() {
            f7.views.current.router.navigate('/settings');
        },
        convertDatetime(dateString: string) {
            const date = new Date(dateString);
            const day = date.getDate();
            const dayString = day < 10 ? `0${day}` : day.toString();
            const month = date.getMonth();
            const monthString = month < 10 ? `0${month}` : month.toString();
            const year = date.getFullYear();
            const hour = date.getHours();
            const hourString = hour < 10 ? `0${hour}` : hour.toString();
            const minutes = date.getMinutes();
            const minutesString = minutes < 10 ? `0${minutes}` : minutes.toString();
            return `${dayString}.${monthString}.${year} ${hourString}:${minutesString}`;
        },
    },
    unmounted() {
        this.vc.destructor();
    }
}
</script>

<style lang="scss" src="./scss/index.scss"></style>
