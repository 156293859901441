<template>
    <div class="light-buying__status expired" v-if="buying">
        <template v-if="buying.account">
            <slot name="timer"></slot>
            <LightBuyingStatusText
                :title="$t('light-payment.status.expired.title')"
                :text="$t('light-payment.status.expired.text')"
                :advice="$t('light-payment.status.expired.advice')"
            />
            <f7-button
                class="theme-type"
            >
                {{ $t('light-payment.status.expired.action') }}
            </f7-button>
        </template>
        <template v-else>
            <SlothBlock
                :title="$t('light-payment.status.expired.no-account.title')"
                :subtitle="$t('light-payment.status.expired.no-account.text')"
                :type="SLOTH_TYPE.RELAX"
            ></SlothBlock>
        </template>
    </div>
</template>

<script setup lang="ts">
import {computed, defineAsyncComponent, PropType, ref, toRef, UnwrapRef, watch} from "vue";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import {ReactiveBuyingOperation} from "@/services/operations/AbstractBuyingService";
import LightBuyingCard from "@/targets/integration/views/light-buying/components/LightBuyingCard.vue";
import LightBuyingAdditional from "@/targets/integration/views/light-buying/components/LightBuyingRequisiteAdditional.vue";
import LightBuyingStatusText from "@/targets/integration/views/light-buying/components/LightBuyingStatusText.vue";
import {SLOTH_TYPE} from "@/entities/enums/SlothType";
import SlothBlock from "@/components/messages/SlothBlock.vue";

const LightBuyingStatusTimer = defineAsyncComponent(() => import("../components/LightBuyingStatusTimer.vue"));

enum TIMER_STATUS_TYPE {
    DEFAULT = "DEFAULT",
    WARNING = "WARNING",
    ALERT = "ALERT"
}

const setTimerColors = {
    DEFAULT: "#1890FF",
    WARNING: "#E69700",
    ALERT: "#FF3B30"
};

const props = defineProps({
    buying: {
        type: Object as PropType<UnwrapRef<PurchaseOperation>>,
        required: true
    },
    timeText: {
        type: Object as PropType<UnwrapRef<string | null>>,
        required: true
    },
    percent: {
        type: Object as PropType<UnwrapRef<number>>,
        required: true
    }
});

const buying: ReactiveBuyingOperation = toRef(props, 'buying');
const requisites = computed(() => buying.value?.requisites);
const percent = toRef(props, 'percent');
const timeText = toRef(props, 'timeText');
const buyingIsAvailable = ref(true);
const timerBorderColor = ref(setTimerColors[TIMER_STATUS_TYPE.DEFAULT]);
const timerTextColor = ref(setTimerColors[TIMER_STATUS_TYPE.DEFAULT]);

if (timeText.value) {
    detectBuyingIsAvailable(timeText.value);
}

watch(timeText, (value) => {
    if (value) {
        detectBuyingIsAvailable(value);
    }
});

function detectBuyingIsAvailable(value: string) {
    const minute = +value.split(':')[0];
    if (minute == 4) {
        timerBorderColor.value = setTimerColors[TIMER_STATUS_TYPE.WARNING];
        timerTextColor.value = setTimerColors[TIMER_STATUS_TYPE.WARNING];
    }
    if (minute == 1 || minute == 0) {
        timerBorderColor.value = setTimerColors[TIMER_STATUS_TYPE.ALERT];
        timerTextColor.value = setTimerColors[TIMER_STATUS_TYPE.ALERT];
    }
    if (minute <= 0) {
        buyingIsAvailable.value = false;
    }
}

</script>

<style src="./index.scss"></style>
<style lang="scss">
.light-buying__status {
    &.expired {
        display: flex;
        align-items: center;
        margin-top: 56px;
        flex-direction: column;
        gap: 32px;

        .button {
            padding: 0 32px;
        }
    }
}
</style>