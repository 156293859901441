export default class ResponseJson<V> {
    private _code: number | null = null;
    private _data: V | null = null;
    private _success: boolean | null = null;
    private _error: string | null = null;

    public ResponseJson() {

    }

    get error(): string | null {
        return this._error;
    }

    set error(value: string) {
        this._error = value;
    }

    get success(): boolean | null {
        return this._success;
    }

    set success(value: boolean) {
        this._success = value;
    }

    get data(): V | null {
        return this._data;
    }

    set data(value: V) {
        this._data = value;
    }

    get code(): number | null {
        return this._code;
    }

    set code(value: number) {
        this._code = value;
    }
}