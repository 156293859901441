<template>
    <f7-app v-bind="vc.config">
        <Transition name="fade" mode="out-in">
            <MainViews v-if="vc.token.value || loading" />
            <f7-view main url="/auth-error" v-else :preload-previous-page="false" :ios-swipe-back="false"></f7-view>
        </Transition>
    </f7-app>
</template>

<script lang="ts" setup>
// @ts-ignore
import {f7, f7ready} from 'framework7-vue';
import AppController from "./ts/AppController";
import MainViews from "../views/MainViews.vue";

const vc = AppController.getInstance();
const loading = ref(true);

onMounted(async () => {
    try {
        loading.value = true;
        await vc.init();
    } finally {
        loading.value = false;
    }
})

import {onMounted, ref} from "vue";
</script>

<style lang="scss">
.no-data-page {
    .page-content {
        .info-block {
            margin-top: 56px;
        }
    }
}
</style>
