<template>
    <div class="my-payments__skeleton">
        <f7-skeleton-block class="skeleton-effect-wave title" />
        <div class="payments">
            <f7-skeleton-block v-for="n in 7" class="skeleton-effect-wave" />
        </div>
    </div>
</template>
<script setup lang="ts">
</script>

<style lang="scss">
.my-payments__skeleton {
    --skeleton-color: #DBDDE4;
    margin: 27px 16px 0;
    .title {
        height: 18px;
        width: 160px;
        border-radius: 8px;
    }
    .payments {
        margin-top: 4px;
        .skeleton-block {
            height: 90px;
            width: 100%;
            border-radius: 8px;

            & + .skeleton-block {
                margin-top: 4px;
            }
        }
    }
}
</style>