<template>
    <div class="light-buying-meta" v-if="buying">
        <div class="light-buying-meta_block">
            <p class="light-buying-meta_text">{{ $t('g.operation.meta.createdAt') }}</p>
            <p class="light-buying-meta_text">{{ buying.getHumanCreateAt() }}</p>
        </div>
        <div class="light-buying-meta_block">
            <p class="light-buying-meta_text">{ $t('g.operations.meta.number') }}</p>
            <p class="light-buying-meta_text">{{ buying.id }}</p>
        </div>
    </div>
</template>

<script setup lang="ts">

import {PropType, toRef, UnwrapRef} from "vue";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import {ReactiveBuyingOperation} from "@/services/operations/AbstractBuyingService";

const props = defineProps({
    buying: {
        type: Object as PropType<UnwrapRef<PurchaseOperation>>,
        required: true
    },
});

const buying: ReactiveBuyingOperation = toRef(props, 'buying');

</script>

<style lang="scss">
.light-buying-meta {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    &_block {
        display: flex;
        flex-direction: column;
        gap: 4px;

        &:nth-child(2) {
            align-items: self-end;
        }
    }
    &_text {
        @include text(12px, 400, #ACB4BE, 14.4px);
    }
}
</style>