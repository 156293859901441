<template>
    <div class="light_buying__content">
        <template v-if="buying">
            <transition name="fade" mode="out-in">
                <template v-if="status === BuyingOperationStatus.CREATED">
                    <LightBuyingStatusCreated
                        @cancel="cancelFindAgent"
                        :cancel="true"
                    >
                        <template #timer>
                            <LightBuyingStatusTimer
                                :timeText="timeText"
                                :percent="percent"
                                :size="100"
                                :borderWidth="8"
                                :hiddenTimer="false"
                                :fontSize="28"
                                class="created"
                            />
                        </template>
                    </LightBuyingStatusCreated>
                </template>
                <template v-else-if="status === BuyingOperationStatus.ACCEPTED">
                    <div class="wrapper-elements">
                        <LightBuyingStatusAccepted
                            :buying="buying"
                            :timeText="timeText"
                            :percent="percent"
                        />
                        <LightBuyingMeta :buying="buying" />
                    </div>
                </template>
                <template v-else-if="status === BuyingOperationStatus.EXPIRED">
                    <LightBuyingStatusExpired
                        :buying="buying"
                        :timeText="timeText"
                        :percent="percent"
                    >
                        <template #timer>
                            <LightBuyingStatusTimer
                                :timeText="timeText"
                                :percent="percent"
                                :hiddenTimer="false"
                                :size="100"
                                :borderWidth="8"
                                :fontSize="28"
                                class="expired"
                            />
                        </template>
                    </LightBuyingStatusExpired>
                </template>
                <template v-else-if="status === BuyingOperationStatus.CANCELED">
                    <LightBuyingStatusCanceled/>
                </template>
                <template v-else-if="status === BuyingOperationStatus.CASHED">
                    <LightBuyingStatusCashed>
                        <template #timer>
                            <LightBuyingStatusTimer
                                :timeText="timeText"
                                :percent="percent"
                                :size="100"
                                :borderWidth="8"
                                :fontSize="28"
                                class="cached"
                            />
                        </template>
                    </LightBuyingStatusCashed>
                </template>
                <template v-else-if="status === BuyingOperationStatus.CONFIRMED">
                    <LightBuyingStatusConfirmed/>
                </template>
                <template v-else-if="status === BuyingOperationStatus.REJECTED">
                    <LightBuyingStatusRejected/>
                </template>
            </transition>
        </template>
    </div>
</template>

<script setup lang="ts">
import {computed, defineAsyncComponent} from 'vue'
import {onMounted, PropType, toRef, UnwrapRef, watch} from "vue";

const LightBuyingStatusCanceled = defineAsyncComponent(() => import("./contents/LightBuyingStatusCanceled.vue"));
const LightBuyingStatusCreated = defineAsyncComponent(() => import("./contents/LightBuyingStatusCreated.vue"));
const LightBuyingStatusAccepted = defineAsyncComponent(() => import("./contents/LightBuyingStatusAccepted.vue"));
const LightBuyingStatusCashed = defineAsyncComponent(() => import("./contents/LightBuyingStatusCashed.vue"));
const LightBuyingStatusConfirmed = defineAsyncComponent(() => import("./contents/LightBuyingStatusConfirmed.vue"));
const LightBuyingStatusRejected = defineAsyncComponent(() => import("./contents/LightBuyingStatusRejected.vue"));
const LightBuyingStatusTimer = defineAsyncComponent(() => import("./components/LightBuyingStatusTimer.vue"));
import {BuyingOperationStatus} from "@enums/BuyingOperationStatus";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import LightBuyingTimerService, {
    ReactivePercent,
    ReactiveTimeText
} from "@/services/light-buying/LightBuyingTimerService";
import LightBuyingStatusExpired from "@/targets/integration/views/light-buying/contents/LightBuyingStatusExpired.vue";
import {f7} from "framework7-vue";
import i18n from "@/langs/i18n";
import LightBuyingMeta from "@/targets/integration/views/light-buying/components/LightBuyingMeta.vue";

const props = defineProps({
    buying: {
        type: Object as PropType<UnwrapRef<PurchaseOperation>>,
        required: true
    },
    timerService: {
        type: Object as PropType<LightBuyingTimerService>,
        required: true
    }
});

const buying = toRef(props, 'buying');
const status = computed(() => buying.value.status);
const timerService: LightBuyingTimerService = props.timerService;
const timeText: ReactiveTimeText = timerService.getTimeText;
const percent: ReactivePercent = timerService.getPercent;

const emits = defineEmits(['timerExpired', 'cancelPurchase']);

function cancelFindAgent() {
    emits('cancelPurchase');
}

onMounted(async () => {
    initTimerService();
});

watch(() => buying.value!.status, () => {
    initTimerService();
});

watch(() => percent.value < 0, (statement) => {
    if(statement)
    emits('timerExpired');
});

function initTimerService() {
    if (status.value === null) return;
    timerService.start(status.value, buying.value?.updatedAt!);
}
</script>

<style lang="scss">
.light_buying__content {
    //height: 100%;
    position: relative;
}
</style>