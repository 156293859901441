<template>
    <div class="light-buying__addition">
        <div class="addition_comment" v-if="comment">
            <p class="title">{{ $t('light-payment.additional.title') }}</p>
            <div class="comment__wrapper">
                <p class="text">{{ comment }}</p>
                <div class="corner"></div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {PropType, Ref, toRef, UnwrapRef} from "vue";

const props = defineProps({
    comment: {
        type: Object as PropType<string | null>,
        required: true
    },
    addressType: {
        type: Object as PropType<string | null>,
        required: true
    }
});
const comment: Ref<string | null> = toRef(props, 'comment');
const addressType: Ref<string | null> = toRef(props, 'addressType');
</script>

<style lang="scss">
.light-buying__addition {
    margin-top: 16px;

    .addition_comment {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .title {
            @include text(14px, 400, var(--grey2), 16.8px);
        }

        .comment__wrapper {
            border-radius: 18px;
            background-color: #F7F7F8;
            padding: 8px 12px;
            position: relative;

            .text {
                word-wrap: break-word;
                @include text(16px, 400, var(--brown1), 19.2px);
            }

            .corner {
                position: absolute;
                left: -5px;
                bottom: 0;
                width: 17px;
                height: 21px;
                background-image: url("data:image/svg+xml,%3Csvg width='17' height='21' viewBox='0 0 17 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.112661 20.1846C5.31266 20.9846 10.446 18.1212 12.1127 16.2879C10.3948 12.1914 21.0006 2.24186 14.0006 2.24148C12.382 2.24148 10.9995 -1.9986 5.11266 1.1846C5.09145 2.47144 5.11266 6.92582 5.11266 7.6842C5.11266 18.1842 -0.887339 19.5813 0.112661 20.1846Z' fill='%23EFEFF4'/%3E%3C/svg%3E%0A");
            }
        }
    }
}

</style>