import {ref, Ref} from "vue";
import {f7} from "framework7-vue";
import Payment from "@/entities/Payment";
import humps from "lodash-humps-ts";
import store from "@target/core/store/store";
import {catchErrors} from "@/decorators/catch-errors";
import ViewController from "@/interfaces/ViewController";
import {autobind} from "@/decorators/autobind";
import i18n from "@/langs/i18n";
import NoAgentsSheetController from "@/components/sheets/NoAgentsSheetController";
import PaymentWithdrawal from "@/entities/PaymentWithdrawal";
import GPWithdrawalController from "@/controllers/GPWithdrawalController";
import GPController from "@/controllers/GPController";
import Account from "@/entities/Account";
import dayjs from "dayjs";

declare const window: Window & typeof globalThis & { Pusher: any, Echo: any }

const defaultValues = {
    time: 0,
}

export default class FindAgentController implements ViewController {
    private static instance?: FindAgentController;
    percent: Ref<number>;
    timeText: Ref<string>;
    time: number;
    interval?: ReturnType<typeof setInterval>;
    intervalRestFindAgents?: ReturnType<typeof setInterval>;

    static getInstance(): FindAgentController {
        if (typeof this.instance === 'undefined') {
            this.instance = new FindAgentController();
        }
        return this.instance;
    }

    private constructor() {
        this.time = 120000;
        this.percent = ref(1);
        this.timeText = ref(this.getTimeText());
    }

    public configure() {
        const account = store.getters['account'].value as Account | undefined;
        const testsIDs = [1, 1000278, 1000106];
        if (!testsIDs.includes(account!.id)) {
            this.defineStatusLiveTime();
            if (this.time <= 0) {
                // @ts-ignore
                // Sentry.captureMessage('Time is 0 on start');
            }
            this.initInterval();
        } else {
            console.info("DEV: timer was stopped")
        }
        this.syncSocket();
        this.startRestApiInterval();
    }

    private startRestApiInterval() {
        this.intervalRestFindAgents = setInterval(async () => {
            await store.dispatch('checkTransactionStatus', null);
        }, 30000);
    }

    private defineStatusLiveTime() {
        const payment = store.getters['payment'].value as Payment | undefined;
        if (typeof payment !== "undefined") {
            this.time = GPController.getStatusMM(payment);
            this.timeText = ref(this.getTimeText());
        }
    }

    private initInterval() {
        const self = this;
        let timeInterval: null | number = null;
        this.interval = setInterval(() => {
            if (timeInterval === null) {
                timeInterval = Math.round(this.time / 1000);
            }
            this.time -= 1000;
            this.timeText.value = this.getTimeText();

            const z: number = +(1 / timeInterval).toFixed(5);
            this.percent.value = this.percent.value - z;

            if (this.time <= 0) {
                clearInterval(this.interval);
                timeInterval = null;

                store.dispatch('cancelPaymentRequestWithExpired', null);
                store.dispatch('setPayment', undefined);
                const popupEl: HTMLDivElement = document.querySelector('.new-payment-popup')!;
                f7.popup.get(popupEl).close();

                const el: HTMLDivElement = document.querySelector('.no-agents-sheet')!;
                const sheet = f7.sheet.create({
                    el,
                });
                sheet.open();

            }
        }, 1000);
    }

    @catchErrors
    private syncSocket() {
        const self = this;

        // window.Echo.channel(localStorage.getItem('ltoken'))
        // .listen('.PaymentAccepted', async (data: { payment: object }) => {
        //     const payment: Payment = humps(data.payment);
        //     await store.dispatch('setPayment', payment);
        //     clearInterval(this.interval);
        //     f7.view.main.router.navigate('/payment');
        // })
        // .listen('.PaymentCanceled', async () => {
        //     self.destructor();
        //     const popupEl: HTMLDivElement = document.querySelector('.new-payment-popup')!;
        //     f7.popup.get(popupEl).close();
        //     const el: HTMLDivElement = document.querySelector('.no-agents-sheet')!;
        //     const sheet = f7.sheet.create({
        //         el,
        //     });
        //     sheet.open();
        // })
        // .listen('.PaymentExpired', async () => {
        //     self.destructor();
        //     const popupEl: HTMLDivElement = document.querySelector('.new-payment-popup')!;
        //     f7.popup.get(popupEl).close();
        //     const el: HTMLDivElement = document.querySelector('.no-agents-sheet')!;
        //     const sheet = f7.sheet.create({
        //         el,
        //     });
        //     sheet.open();
        // });
    }

    @catchErrors
    public async cancelPaymentWithClosePopup() {
        f7.dialog.create({
            title: i18n.global.t("findAgent.cancel.title"),
            text: i18n.global.t("findAgent.cancel.text"),
            buttons: [
                {
                    text: i18n.global.t("findAgent.cancel.no"),
                },
                {
                    text: i18n.global.t("findAgent.cancel.yes"),
                    onClick: async () => {
                        await store.dispatch('cancelPaymentRequest', null);
                        await store.dispatch('setPayment', undefined);
                        const popupEl: HTMLDivElement = document.querySelector('.new-payment-popup')!;
                        f7.popup.get(popupEl).close();
                    }
                }
            ]
        }).open();
    }

    @catchErrors
    public async cancelPaymentWithBack() {
        f7.dialog.create({
            title: i18n.global.t("findAgent.cancel.title"),
            text: i18n.global.t("findAgent.cancel.text"),
            buttons: [
                {
                    text: i18n.global.t("findAgent.cancel.no"),
                },
                {
                    text: i18n.global.t("findAgent.cancel.yes"),
                    onClick: async () => {
                        await store.dispatch('cancelPaymentRequest', null);
                        f7.view.main.router.back('/', {force: true, clearPreviousHistory: true});
                    }
                }
            ]
        }).open();
    }

    getTimeText(): string {
        const ms = this.time / 1000;
        const min = Math.floor(ms / 60);
        const sec = Math.floor(ms % 60);
        const secString = sec < 10 ? `0${sec}` : sec;

        return `${min}:${secString}`;
    }

    beforeIn() {
        // this.time = defaultValues.time;
        // this.percent = ref(1);
        // this.timeText = ref(this.getTimeText());
        // this.configure();
    }

    viewDidLoad() {
        // // console.log('viewDidLoad')
    }

    @autobind
    destructor() {
        clearInterval(this.interval);
        clearInterval(this.intervalRestFindAgents);
        // window.Echo.channel(localStorage.getItem('ltoken')).stopListening('.PaymentAccepted');
        // window.Echo.channel(localStorage.getItem('ltoken')).stopListening('.PaymentCanceled');
        // window.Echo.channel(localStorage.getItem('ltoken')).stopListening('.PaymentExpired');
        FindAgentController.instance = undefined;
    }
}
