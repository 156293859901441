<template>
    <div class="light-buying__loader">
        <f7-preloader size="28"/>
    </div>
</template>

<script setup lang="ts">

</script>

<style lang="scss">
.light-buying__loader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>