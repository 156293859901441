import "reflect-metadata";
// @ts-ignore
import {createApp} from 'vue';
// import Framework7 from './f7/plugins';
// import Framework7Vue, {f7, registerComponents} from './f7/components';

// @ts-ignore
import Framework7 from 'framework7/lite-bundle'
// @ts-ignore
import Framework7Vue, { registerComponents } from 'framework7-vue/bundle'
import {f7, useStore} from "framework7-vue";


import 'framework7/css/bundle';
// import '../scss/icons.scss';
import '@/scss/app.scss';
import '@/scss/icons.scss';
import '@target/scss/app.scss';
import '@/scss/animations.scss';
import App from '@target/components/App/App.vue';
// @ts-ignore
import {color} from "@target/core/directives";
// @ts-ignore
import Pusher from "pusher-js";
// @ts-ignore
import Echo from "laravel-echo";
// @ts-ignore
import store from "@target/core/store/store";
import Message, {MessageType} from "@/entities/Message";

import i18n from "@/langs/i18n";
// import * as Sentry from "@sentry/vue";

import disableBounce from "@/helpers/disable-bounce";
import * as Sentry from "@sentry/vue";

Framework7.use(Framework7Vue);
const app = createApp(App);
app.use(i18n);
registerComponents(app);

Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
        Sentry.replayIntegration({
            maskAllText: true,
            blockAllMedia: true,
        }),
    ],
});

app.mount('#app');
app.directive(color.name, color.fn);

disableBounce(window);

// Sentry.init({
//     main,
//     dsn: "https://fff7994c0dbd4e7d81a7abacb4869780@o4505205241544704.ingest.sentry.io/4505205261139968",
//     // integrations: [
//     //     new Sentry.Replay(),
//     // ],
//     // // Performance Monitoring
//     // tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//     // // Session Replay
//     // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

// window.Echo.connector.pusher.connection.bind('state_change', async function (states: any) {
//     if (states.current === 'connecting') {
//         const appController = AppController.getInstance();
//         appController.socketIsConnecting.value = true;
//     }
//     if ((states.previous === "unavailable" && states.current === 'connecting') ||
//         (states.previous === "unavailable" && states.current === 'connected')) {
//         await updateView();
//     }
//     if (states.current === 'failed') {
//         await updateView();
//     }
//
//     setTimeout(() => {
//         const appController = AppController.getInstance();
//         appController.socketIsConnecting.value = false;
//     }, 2000);
//     if (states.current === 'disconnected') {
//         const appController = AppController.getInstance();
//         appController.socketIsConnecting.value = false;
//     }
// });

// async function updateView() {
//     try {
//         switch (f7.views.current.router.currentRoute.url) {
//             case "/popup/new-payment":
//                 await store.dispatch('fetchAccount', null);
//                 await store.dispatch('checkTransactionStatus', null);
//                 const payment1: PaymentWithdrawal = useStore('payment');
//                 if (payment1) {
//                     f7.view.main.router.navigate('/payment');
//                 }
//                 break;
//             case "/popup/withdrawal/create":
//                 await store.dispatch('fetchAccount', null);
//                 await store.dispatch('checkWithdrawalStatus', null);
//                 const payment2: PaymentWithdrawal = useStore('paymentWithdrawal');
//                 if (payment2 && payment2.requisitesA) {
//                     f7.view.main.router.navigate('/withdrawal');
//                 }
//                 break;
//             case "/payments":
//                 await store.dispatch('fetchAccount', null);
//                 await store.dispatch('fetchMyPayments', {refresh: true});
//                 break;
//             case "/payment":
//                 await store.dispatch('fetchAccount', null);
//                 await store.dispatch('checkTransactionStatus', null);
//                 break;
//             case "/withdrawal":
//                 await store.dispatch('fetchAccount', null);
//                 await store.dispatch('checkWithdrawalStatus', null);
//                 break;
//         }
//         const appController = AppController.getInstance();
//         appController.socketIsConnecting.value = false;
//     } catch (e) {}
// }
