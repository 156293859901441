<template>
    <f7-page ref="self" :page-content="false" class="settings-page">
        <f7-navbar>
            <f7-nav-left>
                <a href="#" class="link icon-only" @click="CLOSE">
                    <i class="icon-back icon"></i>
                </a>
            </f7-nav-left>
            <f7-nav-title>
                {{ $t('g.settings.page.title') }}
            </f7-nav-title>
            <f7-nav-right></f7-nav-right>
        </f7-navbar>
        <f7-page-content>
            <p class="list-title">{{ $t('settings.my-profile') }}</p>
            <f7-list class="list-outline list-strong list-dividers">
                <f7-list-item :title="$t('g.settings.page.links.phone')"
                              :after="account.contact.phone"></f7-list-item>
                <f7-list-item :title="$t('g.settings.page.links.name')" link="/settings/change-short-name">
                    <template #after>
                        <template v-if="account.shortName && account.shortName.length > 0">
                            {{ account.shortName }}
                        </template>
                        <template v-else>
                            <i class="icon i-svg-warning"></i>
                        </template>
                    </template>
                </f7-list-item>
                <f7-list-item :title="$t('settings.user-id')" :after="account.id"/>
                <f7-list-item :title="$t('g.settings.page.links.language')" link="/settings/lang"
                              :after="detectCountryName(account.settings.localization)"></f7-list-item>
                <f7-list-item :title="$t('settings.remove-account')" link="/remove-account"></f7-list-item>
                <!-- <f7-list-item :title="$t('g.settings.page.links.currency')" link="/settings/currency" :after="account.currency.name"></f7-list-item> -->
            </f7-list>
<!--            <f7-list class="separate-list list-outline list-strong list-dividers">-->
<!--                <f7-list-item @click="removeAccountEvent">-->
<!--                    <template #title>-->
<!--                        <span>{{ $t('settings.remove-account') }}</span>-->
<!--                    </template>-->
<!--                </f7-list-item>-->
<!--            </f7-list>-->
            <div class="version-wrapper">
                <p class="version" @click="openTechPage">{{ $t('g.version') }} {{ version }}</p>
            </div>
        </f7-page-content>
    </f7-page>
</template>

<script lang="ts" setup>
import {useI18n} from 'vue-i18n'
import {f7} from 'framework7-vue';
import {computed, defineComponent, Ref, ref} from "@vue/runtime-core";
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import Account from '@/entities/Account';
// @ts-ignore
import store from "@target/core/store/store";
import GlobalRequisitesController from "@/views/requisites-group/GlobalRequisitesController";
import Requisite from "@/entities/Requisite";
import {getDevice} from "framework7/shared/get-device";

const {t} = useI18n({useScope: 'global'});
const account = computed((): Account => store.getters.account.value);
const requisitePs = GlobalRequisitesController.getInstance();
const requisites = requisitePs.requisites;
const version = import.meta.env.VITE_VERSION;
let techCount = 0;

const CLOSE = () => {
    f7.view.main.router.back('/payments', {force: true, clearPreviousHistory: true});
}

const openTechPage = () => {
    techCount++;
    if (techCount === 10) {
        f7.dialog.prompt("", "Password", (password: string) => {
            if (password === '666') {
                f7.view.current.router.navigate('/settings/tech');
            }
        })
        techCount = 0;
    }
}
const detectCountryName = (systemName: string) => {
    switch (systemName) {
        case 'ru':
            return t("g.settings.selectLanguage.languages.ru");
        case 'en':
            return t("g.settings.selectLanguage.languages.en");
    }
    return systemName;
}

const removeAccountEvent = () => {
    f7.dialog.create({
        title: t('alerts.remove-account.title'),
        text: t('alerts.remove-account.text'),
        buttons: [
            {
                text: t('alerts.remove-account.no'),
            },
            {
                text: t('alerts.remove-account.yes'),
                onClick: async () => {
                    try {
                        f7.preloader.show();
                        await store.dispatch('removeAccount');
                        await AppController.getInstance().logout();
                    } catch (e: any) {
                        f7.dialog.alert(t('g.errors.alert.default-text'), t('g.errors.alert.default-title'))
                    } finally {
                        f7.preloader.hide();
                    }
                }
            }
        ]
    }).open();
}
</script>

<style lang="scss" src="@/scss/views/settings-view.scss"></style>
<style lang="scss">
.settings-page__navbar {
    --f7-navbar-bg-color: #FFF;
}
</style>