import Account from "@/entities/Account";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import {Expose, Type} from "class-transformer";
import LightPaymentPidResponseCredentials from "@models/responses/light-payment-pid/LightPaymentPidResponseCredentials";
import LightPaymentPidResponseError from "@models/responses/light-payment-pid/LightPaymentPidResponseError";

export default class LightPaymentPidResponse {
    @Expose({name: 'profile'})
    private readonly _profile: Account;

    @Expose({name: 'payment'})
    @Type(() => PurchaseOperation)
    private readonly _payment: PurchaseOperation;

    @Expose({name: 'error'})
    @Type(() => LightPaymentPidResponseError)
    private readonly _error: LightPaymentPidResponseError | null

    @Expose({name: 'credentials'})
    @Type(() => LightPaymentPidResponseCredentials)
    private readonly _credentials: LightPaymentPidResponseCredentials;

    public constructor(
        profile: Account,
        payment: PurchaseOperation,
        credentials: LightPaymentPidResponseCredentials,
        error: LightPaymentPidResponseError | null
    ) {
        this._profile = profile;
        this._payment = payment;
        this._credentials = credentials;
        this._error = error;
    }

    get error(): LightPaymentPidResponseError | null {
        return this._error;
    }

    get profile(): Account {
        return this._profile;
    }
    get payment(): PurchaseOperation {
        return this._payment;
    }

    get credentials(): LightPaymentPidResponseCredentials {
        return this._credentials;
    }
}