import MainApiService from "@/services/api-service/MainApiService";
import LightPaymentPidResponse from "@models/responses/light-payment-pid/LightPaymentPidResponse";
import {plainToInstance} from "class-transformer";

export default class LightAppService {
    async fetchDataWithPid(pid: string): Promise<LightPaymentPidResponse> {
        const response: object = await MainApiService.getInstance().post(`/api/mobile/init/paylink`, {
            pid
        });
        return plainToInstance(LightPaymentPidResponse, response, {
            excludeExtraneousValues: true,
            excludePrefixes: ['_']
        });
    }
}