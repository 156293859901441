<template>
    <f7-page
        class="payments-view"
        name="main"
        :page-content="false"
        @page:beforein="pageBeforeInEvent"
        @page:afterin="afterIn"
        @page:beforeout="pageBeforeOut"
    >
        <Navbar
            :viewNavbarBalance="viewNavbarBalance"
            :viewNavbarLogo="viewNavbarLogo"
            @openSheetModalForNewPayment="openSheetModalForNewPayment"
        />
        <f7-page-content ptr @ptr:refresh="eventPtrRefresh" infinite @infinite="eventInfinite">
            <template v-if="(account && payments) && payments.length > 0 && account.balance!.amountC != 0">
                <Transition name="fade" mode="out-in">
                    <BalanceBlock
                        v-if="account"
                        @openSheetModalForWithdrawal="openSheetModalForWithdrawal"
                        @openSheetModalForNewPayment="openSheetModalForNewPayment"
                        @infoClick="openBalanceHintSheet"
                    />
                    <BalanceBlockSkeleton v-else/>
                </Transition>
            </template>

            <Transition name="fade" mode="out-in">
                <template v-if="payments">
                    <Transition name="fade" mode="out-in">
                        <template v-if="payments.length > 0">
                            <my-payments @openSheetModalForNewPayment="openSheetModalForNewPayment"/>
                        </template>

                        <template v-else>
                            <SlothBlock
                                :title="$t('g.payments.noPayments.title')"
                                :subtitle="$t('g.payments.noPayments.text')"
                                :type="SLOTH_TYPE.WIN"
                            >
                                <template #button>
                                    <f7-button
                                        preloader
                                        class="theme-type width-100"
                                        :loading="nextbtnLoading"
                                        :disabled="nextbtnLoading"
                                        @click="openSheetModalForNewPayment"
                                    >{{ $t('payments.newPayment') }}
                                    </f7-button>
                                </template>
                            </SlothBlock>
                        </template>
                    </Transition>
                </template>
                <MyPaymentsSkeleton v-else/>
            </Transition>
        </f7-page-content>

        <Transition name="fade" mode="out-in">
            <template v-if="(payments && account) && payments.length > 0 && account.balance!.amountC == 0">
                <div class="bottom-block" ref="bottom-block">
                    <div class="row-actions">
                        <f7-button preloader @click="openSheetModalForNewPayment" :loading="nextbtnLoading"
                                   :disabled="nextbtnLoading" fill class="primary">{{ $t('payments.newPayment') }}
                        </f7-button>
                    </div>
                </div>
            </template>
        </Transition>

        <no-agents-sheet/>
        <ban-sheet ref="ban-sheet" v-if="account?.settings.ban"/>
        <balance-hint-sheet v-if="isBalanceHintSheet" @closed="isBalanceHintSheet = false"/>
    </f7-page>
</template>

<script lang="ts">
import {useI18n} from 'vue-i18n'
import PaymentsViewController from "./ts/PaymentsViewController";
import {computed, defineAsyncComponent, onMounted, ref} from "vue";
import Navbar from "@/targets/integration/views/payments/components/Navbar.vue";
import SlothBlock from "@/components/messages/SlothBlock.vue";
// @ts-ignore
import store from "@target/core/store/store";
import Account from "@/entities/Account";
import PaymentSystem from "@/entities/PaymentSystem";
import Message, {MessageType} from "@/entities/Message";
import {f7, useStore} from "framework7-vue";
import {getDevice} from "framework7/shared/get-device";
import GlobalRequisitesController from "@/views/requisites-group/GlobalRequisitesController";
import BalanceBlock from '@components/atomic/balance/BalanceBlock.vue';
import {SLOTH_TYPE} from "@/entities/enums/SlothType";
import AppController from "@/targets/integration/components/App/ts/AppController";
import MyPaymentsSkeleton from "@/targets/integration/views/payments/components/MyPaymentsSkeleton.vue";
import BalanceBlockSkeleton from "@/targets/integration/views/payments/components/BalanceBlockSkeleton.vue";
import ErrorsService from "@/services/errors-service/ErrorsService";
import NewPaymentController from "@/views/new-payment/NewPaymentController";
import RouterService from "@/services/RouterService";

export default {
    computed: {
        SLOTH_TYPE() {
            return SLOTH_TYPE
        }
    },
    components: {
        BalanceBlockSkeleton,
        MyPaymentsSkeleton,
        SlothBlock,
        BalanceBlock,
        'ban-sheet': defineAsyncComponent(() => import('@/components/sheets/BanSheet.vue')),
        'balance-hint-sheet': defineAsyncComponent(() => import('@components/sheets/BalanceHintSheet.vue')),
        'no-agents-sheet': defineAsyncComponent(() => import('@/components/sheets/NoAgentsSheet.vue')),
        'my-payments': defineAsyncComponent(() => import('./components/MyPayments.vue')),
        'message-error': defineAsyncComponent(() => import('@/components/messages/error/MessageError.vue')),
        Navbar
    },
    setup() {
        const routerService: RouterService = AppController.getInstance().routerService;
        const {t} = useI18n({useScope: 'global'});
        const vc = PaymentsViewController.getInstance();
        const account = useStore('account');
        const paymentSystems = computed((): PaymentSystem[] => store.getters.paymentSystems.value);
        const payment = useStore('payment');
        const payments = useStore('myPayments');
        const noPaymentSystemsMessage: Message = {
            title: t("g.payments.pause.title"),
            text: t("g.payments.pause.text"),
            type: MessageType.error
        };
        const allowInfinity = false;
        const nextbtnLoading = ref(false);
        const {init} = vc;
        const requisitePs = GlobalRequisitesController.getInstance();
        const btnWithdrawalLoading = ref(false);
        const popupForNewPayment = null;
        const viewNavbarBalance = ref(false);
        const viewNavbarLogo = ref(false);
        const appIsReady = AppController.getInstance().isReady;
        const isBalanceHintSheet = ref(false);
        const totalOperations = -1;

        return {
            routerService,
            totalOperations,
            isBalanceHintSheet,
            appIsReady,
            viewNavbarLogo,
            viewNavbarBalance,
            requisitePs,
            btnWithdrawalLoading,
            t,
            init,
            nextbtnLoading,
            payments,
            popupForNewPayment,
            payment,
            noPaymentSystemsMessage,
            paymentSystems,
            account,
            vc,
            allowInfinity,
        }
    },
    mounted() {
        // this.detectBottomPadding();
        // this.detectScrollObserver();
        this.updateInfinityScroll();
        // test();
    },
    watch: {
        'payments'() {
            this.detectBottomPadding();
            setTimeout(() => {
                this.updateInfinityScroll();
            }, 700)
        },
        async 'appIsReady'(value: boolean) {
            if (value) {
                await this.vc.updateAccountData();
                await this.fetchOperations({refresh: true});
            }
        }
    },
    methods: {
        async fetchOperations(config: any) {
            const {meta} = await store.dispatch('fetchMyPayments', config);
            this.totalOperations = meta.total;
        },
        openBalanceHintSheet() {
            this.isBalanceHintSheet = true;
        },
        pageBeforeOut() {
            this.hideBottomBlock();
        },
        hideBottomBlock() {
            const el = this.$refs['bottom-block'] as HTMLDivElement;
            if (el) {
                el.classList.remove('slide-in-bottom');
            }
        },
        detectBottomPadding() {
            if (this.payments.length > 0 && this.account.balance!.amountC != 0) {
                const self = document.querySelector('.payments-view')!;
                (self as HTMLElement).style.setProperty('--payments-view_bottom-block', '0');
            }
        },
        prepareLinkName(link: string) {
            return link.replace(/(http)s?:\/\//, '').replace(/\..+/, '');
        },
        TO_SETTINGS() {
            f7.views.current.router.navigate('/settings');
        },
        async pageBeforeInEvent() {
            if (this.appIsReady) {
                await this.vc.updateAccountData();
                await this.fetchOperations({refresh: true});
            }
        },
        async afterIn() {
            AppController.getInstance().setPaymentRequestData(null);
        },
        updateInfinityScroll() {
            if (!this.payments || this.payments.length >= this.totalOperations) {
                (document.querySelector('.infinite-scroll-preloader') as HTMLDivElement).style.display = 'none';
                this.allowInfinity = false;
            } else {
                (document.querySelector('.infinite-scroll-preloader') as HTMLDivElement).style.display = 'block';
                this.allowInfinity = true;
            }
        },
        async openSheetModalForWithdrawal(callback?: Function) {
            try {
                // await store.dispatch('setPaymentWithdrawal', undefined);
                // await this.requisitePs.fetchAllRequisites();
                // await store.dispatch('fetchRates');
                // await this.requisitePs.checkActiveRequisite();
                // await this.requisitePs.fetchAddressTypes();

                this.openPopupNoAgents();
                return;

                // f7.view.main.router.navigate('/popup/withdrawal')
            } catch (e: any) {
                f7.dialog.alert(this.t('g.errors.alert.default-text'), this.t('g.errors.alert.default-title'));
            } finally {
                if (typeof callback === "function") callback();
                // this.btnWithdrawalLoading = false;
            }
        },
        openPopupNoAgents() {
            const el: HTMLDivElement = document.querySelector('.no-agents-sheet')!;
            const sheet = f7.sheet.create({
                el,
            });
            sheet.open();
        },
        async openSheetModalForNewPayment(callback?: Function) {
            const self = this;
            try {
                self.nextbtnLoading = true;
                await this.routerService.toCreatePaymentPopup();
            } catch (e) {
                f7.dialog.alert(this.t("g.payments.alert.first"), this.t("g.payments.alert.second"), () => {
                });
            } finally {
                if (typeof callback === "function") callback();
                else this.nextbtnLoading = false;
            }
        },
        async eventInfinite() {
            if (this.allowInfinity) {
                this.allowInfinity = false;
                await this.fetchOperations({refresh: false});
            }
        },
        async eventPtrRefresh(done: any) {
            try {
                await this.vc.updateMyAccount();
                await this.vc.updateMyPayments();
                // await store.dispatch('fetchPaymentSystems', null);
                // await store.dispatch('fetchCurrencies', null);
                this.updateInfinityScroll();
                this.allowInfinity = true;
            } catch (e: any) {
                ErrorsService.of().handle(e);
            } finally {
                done();
            }
        }
    },
    unmounted() {
        this.vc.destructor();
    }
}
</script>

<style src="./index.scss" lang="scss"></style>
